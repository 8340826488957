.intro-section {
    background-image: url(../../assets/beard_at_kilby_court.jpg);
    height: 80vh;
    background-repeat: no-repeat;
    background-color: #080C19;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.highlight {
    height: 75vh;
    text-align: center;
    color: #F8CE27;
    text-shadow: 5px 5px #323038;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-weight: bold;
}

.beard-o-bees {
    font-size: 100px;
    font-family: 'Bakbak One', cursive;
    line-height: 100px;
}

.icon {
    font-size: 50px;
    line-height: 50px;
    background-color: #000;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.icon:hover {
    color: #F8CE27;
}


@media screen and (max-width: 1000px) {
    .intro-section {
        height: 70vh;
    }

    .highlight {
        height: 69vh;
    }

    .beard-o-bees {
        font-size: 60px;
    }

    .icon {
        padding-bottom: 40vh;
        height: 60vh;
    }
}