#music {
    height: 95vh;
    background-color: #000;
    padding: 90px 0;

}

.albums {
    display: flex;
    justify-content: space-around;
    align-items: start;
}

@media screen and (max-width: 1000px) {
    .albums {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .albums iframe {
        margin-top: 10px;
    }
}