.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #080C19;
    height: 75px;
}

.header img {
    margin-left: 50px;
    border-radius: 25px;
}

.navigation {
    display: flex;
    justify-content: end;
    font-size: 20px;
    margin-right: 35px
}

.link {
    margin-right: 20px;
    font-family: 'Bakbak One', cursive;
    cursor: pointer;
    color: #fbdd1b;
}

.link:hover {
    color: #fbdd1b;
    opacity: .5;
}

.mobile-container {
    display: none;
}

.mobile-menu {
    display: none;
}

.mobile-menu-items {
    display: none;
}

@media screen and (max-width: 1000px) {
    .header img {
        margin-left: 10px;
    }

    .navigation {
        display: none;
    }

    .mobile-container {
        display: block;
    }

    .mobile-menu {
        display: block;
        top: 0;
        font-size: 50px;
        cursor: pointer;
        color: #fbdd1b;
        padding-right: 20px;
        text-align: right;
    }

    .mobile-menu-close {
        display: block;
        top: 0;
        font-size: 50px;
        cursor: pointer;
        color: #fbdd1b;
        padding-right: 20px;
        text-align: right;
    }

    .mobile-menu-items {
        display: block;
        position: absolute;
        top: 20;
        left: 0;
        background-color: #080C19;
        width: 100%;
        text-align: center;
        border-top: 5px solid #fbdd1b;
        border-bottom: 5px solid #fbdd1b;
    }

    .link-item {
        padding: 10px;
    }

    .link {
        font-size: 18px;
        padding: 20px;
    }

    /* .test { */
    /* border-top: 5px solid #fbdd1b;
        border-bottom: 5px solid #fbdd1b;
    } */
}