@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&family=Roboto&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Bakbak One', cursive !important;
  color: #fbdd1b;
  text-align: center;
  font-size: 40px;
}