@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Bakbak One', cursive !important;
  color: #fbdd1b;
  text-align: center;
  font-size: 40px;
}
/*
PALATE A
#FBDD1B
#323038
#5D524A
#8E7F4C
#EBB216  less bright gold #E0BE39
*/
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #080C19;
    height: 75px;
}

.header img {
    margin-left: 50px;
    border-radius: 25px;
}

.navigation {
    display: flex;
    justify-content: end;
    font-size: 20px;
    margin-right: 35px
}

.link {
    margin-right: 20px;
    font-family: 'Bakbak One', cursive;
    cursor: pointer;
    color: #fbdd1b;
}

.link:hover {
    color: #fbdd1b;
    opacity: .5;
}

.mobile-container {
    display: none;
}

.mobile-menu {
    display: none;
}

.mobile-menu-items {
    display: none;
}

@media screen and (max-width: 1000px) {
    .header img {
        margin-left: 10px;
    }

    .navigation {
        display: none;
    }

    .mobile-container {
        display: block;
    }

    .mobile-menu {
        display: block;
        top: 0;
        font-size: 50px;
        cursor: pointer;
        color: #fbdd1b;
        padding-right: 20px;
        text-align: right;
    }

    .mobile-menu-close {
        display: block;
        top: 0;
        font-size: 50px;
        cursor: pointer;
        color: #fbdd1b;
        padding-right: 20px;
        text-align: right;
    }

    .mobile-menu-items {
        display: block;
        position: absolute;
        top: 20;
        left: 0;
        background-color: #080C19;
        width: 100%;
        text-align: center;
        border-top: 5px solid #fbdd1b;
        border-bottom: 5px solid #fbdd1b;
    }

    .link-item {
        padding: 10px;
    }

    .link {
        font-size: 18px;
        padding: 20px;
    }

    /* .test { */
    /* border-top: 5px solid #fbdd1b;
        border-bottom: 5px solid #fbdd1b;
    } */
}
.intro-section {
    background-image: url(/static/media/beard_at_kilby_court.4440cd83.jpg);
    height: 80vh;
    background-repeat: no-repeat;
    background-color: #080C19;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.highlight {
    height: 75vh;
    text-align: center;
    color: #F8CE27;
    text-shadow: 5px 5px #323038;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-weight: bold;
}

.beard-o-bees {
    font-size: 100px;
    font-family: 'Bakbak One', cursive;
    line-height: 100px;
}

.icon {
    font-size: 50px;
    line-height: 50px;
    background-color: #000;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.icon:hover {
    color: #F8CE27;
}


@media screen and (max-width: 1000px) {
    .intro-section {
        height: 70vh;
    }

    .highlight {
        height: 69vh;
    }

    .beard-o-bees {
        font-size: 60px;
    }

    .icon {
        padding-bottom: 40vh;
        height: 60vh;
    }
}
#meet-the-band {
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.band-section {
    border-top: 5px solid #fbdd1b;
    border-bottom: 5px solid #fbdd1b;
    padding: 40px 0;
}

.band-intro {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 20px;
}

.band-members {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
}

@media screen and (max-width: 1000px) {
    #meet-the-band {
        height: 200vh;
    }

    .band-members {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        color: #fff;
    }

    .band-members img {
        width: 150px;
    }
}
#music {
    height: 95vh;
    background-color: #000;
    padding: 90px 0;

}

.albums {
    display: flex;
    justify-content: space-around;
    align-items: start;
}

@media screen and (max-width: 1000px) {
    .albums {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .albums iframe {
        margin-top: 10px;
    }
}
@media screen and (max-width: 1000px) {
    iframe {
        height: 200px !important;
        width: 320px !important;
    }
}
#videos {
    /* background-color: #5D524A; */
    background-image: linear-gradient(#322b19, #b8994c, #EBB216);
    /* background-image: linear-gradient(#5D524A, #a88266, #d78a52); */
    height: 95vh;
    padding: 90px 0;
    /* background-image: url(../../assets//guitars.jpg); */
    /* background-position: center; */
    /* background-attachment: fixed; */
    /* background-size: cover; */
    /* filter: blur(8px); */
    /* -webkit-filter: blur(8px); */
}

.video-background {
    position: absolute;
    width: 100vw;
    height: 95vh;
    z-index: -1;
    /* filter: blur(4px); */
    /* opacity: .7; */
}

.video-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-top: 50px
}

.vid-card {
    color: #fff;
    padding-top: 20px;
}

.vid-card:hover {
    background-color: #fff;
    color: #000;
}

.vid-image {
    width: 200px;
    margin: 0 20px;
}

.vid-title {
    font-size: 16x;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .vid-image {
        width: 85px;
        margin: 0 5px;
    }

    .vid-title {
        display: none;
    }
}
#contact {
    background-color: #000;
    height: 95vh;
    padding: 60px 0;
}

.contact-form {
    display: flex;
    justify-content: space-evenly;
    background: #000;
}

form {
    width: 50%;
    padding: 40px;
}

.social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.form-item {
    margin-bottom: 20px;

}

input {
    width: 100%;
    height: 30px;
    font-family: 'Roboto', sans-serif;
}

textarea {
    width: 100%;
    height: 200px;
    font-family: 'Roboto', sans-serif;
}

button {
    font-family: 'Bakbak One', cursive !important;
    font-size: 16px !important;
    background-color: #F8CE27;
    height: 30px;
    width: 100px;
}

.social-icon {
    font-size: 50px;
    line-height: 50px;
    color: #F8CE27;
    text-align: center;
    cursor: pointer;
}

.response {
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}


@media screen and (max-width: 1000px) {
    .contact-form img {
        display: none;
    }

    .contact-form {
        display: block;
        padding: 20px;
    }

    .social-container {
        justify-content: space-evenly;
    }

    form {
        width: 100%;
        padding: 0;
    }

    input {
        width: 100%;
    }

    textarea {
        width: 100%;
    }
}
