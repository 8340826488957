#meet-the-band {
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.band-section {
    border-top: 5px solid #fbdd1b;
    border-bottom: 5px solid #fbdd1b;
    padding: 40px 0;
}

.band-intro {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 20px;
}

.band-members {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
}

@media screen and (max-width: 1000px) {
    #meet-the-band {
        height: 200vh;
    }

    .band-members {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        color: #fff;
    }

    .band-members img {
        width: 150px;
    }
}