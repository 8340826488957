#contact {
    background-color: #000;
    height: 95vh;
    padding: 60px 0;
}

.contact-form {
    display: flex;
    justify-content: space-evenly;
    background: #000;
}

form {
    width: 50%;
    padding: 40px;
}

.social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.form-item {
    margin-bottom: 20px;

}

input {
    width: 100%;
    height: 30px;
    font-family: 'Roboto', sans-serif;
}

textarea {
    width: 100%;
    height: 200px;
    font-family: 'Roboto', sans-serif;
}

button {
    font-family: 'Bakbak One', cursive !important;
    font-size: 16px !important;
    background-color: #F8CE27;
    height: 30px;
    width: 100px;
}

.social-icon {
    font-size: 50px;
    line-height: 50px;
    color: #F8CE27;
    text-align: center;
    cursor: pointer;
}

.response {
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}


@media screen and (max-width: 1000px) {
    .contact-form img {
        display: none;
    }

    .contact-form {
        display: block;
        padding: 20px;
    }

    .social-container {
        justify-content: space-evenly;
    }

    form {
        width: 100%;
        padding: 0;
    }

    input {
        width: 100%;
    }

    textarea {
        width: 100%;
    }
}